<script lang="ts" setup>
  import VLogo from '~/components/header/VLogo.vue';
  import { normalizeStyle } from 'vue';

  interface IProps {
    radius?: string;
    type?: 'movie' | 'series' | 'continue' | 'borderless' | 'channel';
    size?: 'large' | 'middle' | 'small';
  }
  const props = withDefaults(defineProps<IProps>(), {
    type: 'movie',
    size: 'middle',
  });
</script>

<template>
  <div
    class="loader"
    :class="[`loader--${props.type}`]"
    :style="
      normalizeStyle({
        borderRadius: props.radius,
      })
    "
  >
    <v-logo class="loader__logo" :size="props.size" media="small" />
  </div>
</template>

<style lang="scss" scoped>
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 100%;
    width: 100%;
    &__logo {
      animation: pulse 2s infinite;
    }
    &--movie {
      border-radius: 32px;
      aspect-ratio: 2 / 3;
    }
    &--series {
      border-radius: 16px;
    }
    &--continue {
      border-radius: 32px;
    }
    &--borderless {
      border: none;
    }
    &--channel {
      border-radius: 24px;
    }
    @media (max-width: $retina) {
      &--movie {
        border-radius: 16px;
      }
      &--continue {
        border-radius: 16px;
      }
      &--channel {
        border-radius: 12px;
      }
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }
</style>
